





























































































































































































































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {IGebruiker} from '@/models/IGebruiker';
import {min, required} from '@/utils/validation';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {buildEmptyImportTemplate, IImportTemplate, MappedObject} from '@/models/IImportTemplate';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';

@Component({
    filters: {},
    components: {
        SimpleDeleteDialog,
        SimpleMessageWrapper,
    },
})
export default class ImportTemplateEdit extends Mixins(ShowsMessages, ListsObjects, AuthorizationMixin) {
    public loadingData: boolean = true;
    public importDef: IImportTemplate | null = null;
    public selectedColumnTemplate: IImportColumnTemplate | null = null;

    public mappableFields: string[] | null = null;
    public mappedObjects: Array<{ label: string, value: MappedObject }> = [
        {label: 'Vestiging', value: MappedObject.VESTIGING},
        {label: 'Werkzame Personen', value: MappedObject.WERKZAME_PERSONEN},
        {label: 'Centrale Berichtgever', value: MappedObject.CB},
        {label: 'Adres', value: MappedObject.ADRES},
    ];

    private isEditing: boolean = false;
    private exampleDialog: boolean = false;
    private deleteDialog: boolean = false;
    private textMatches: string[] = [];
    private requiredCreateFieldsVestiging: string[] = [
        'naam',
        'adres.straat',
        // TODO: add more fields once these are required (it is yet to be determined which fields are).
    ];
    private requiredCreateFieldsAdres: string[] = [
        'postcode',
        'huisnummer',
        'huisletter',
        'toevoeging',
    ];

    public get highlightedExampleText() {
        if (!this.selectedColumnTemplate || !this.importDef?.exampleText) {
            return '';
        }
        const highlightedTexts = [];
        this.textMatches = [];
        for (const text of this.importDef.exampleText.split('\n')) {
            const start = this.selectedColumnTemplate.startPosition - 1;
            const end = start + this.selectedColumnTemplate.length;
            this.textMatches.push(text.slice(start, end));
            highlightedTexts.push([
                text.slice(0, start),
                '<span style="background:yellow">',
                text.slice(start, end),
                '</span>',
                text.slice(end),
            ].join(''));
        }
        return highlightedTexts.join('<br />');
    }

    public get isVestigingType() {
        return this.importDef?.mappedObject ===  MappedObject.VESTIGING;
    }

    public get isAdresType() {
        return this.importDef?.mappedObject ===  MappedObject.ADRES;
    }

    public get isWerkzamePersonenType() {
        return this.importDef?.mappedObject ===  MappedObject.WERKZAME_PERSONEN;
    }

    public get hasAnyIdentifiers() {
        if (this.importDef!.mappedObject === MappedObject.WERKZAME_PERSONEN.valueOf()) {
            return true;
        }
        return !!this.importDef?.importColumnTemplates.find((columnTemplate: IImportColumnTemplate) => {
            return ['id', 'vestigingNummer'].includes(columnTemplate.mappedField);
        });
    }

    public get isMissingRequiredFieldsForVestigingType() {
        return !this.hasAnyIdentifiers && this.missingRequiredFieldsForVestigingType.length > 0;
    }

    public get missingRequiredFieldsForVestigingType() {
        const fields = [];
        for (const requiredField of this.requiredCreateFieldsVestiging) {
            if (!this.hasField(requiredField)) {
                fields.push(requiredField);
            }
        }
        return fields;
    }

    public get missingRequiredFieldsForAdresType() {
        const fields = [];
        if (this.hasField('perceelnummer') || this.hasField('id')) {
            return [];
        }
        for (const requiredField of this.requiredCreateFieldsAdres) {
            if (!this.hasField(requiredField)) {
                fields.push(requiredField);
            }
        }
        return fields;
    }

    public hasField(fieldName: string) {
        return this.importDef?.importColumnTemplates.map((columnTemplate: IImportColumnTemplate) => {
            return columnTemplate.mappedField;
        }).includes(fieldName);
    }

    public get sortedImportColumnTemplates() {
        return this.importDef?.importColumnTemplates.sort(
            (a: any|IImportColumnTemplate, b: any|IImportColumnTemplate) => {
            if (a.startPosition > b.startPosition) {
                return 1;
            } else if (a.startPosition < b.startPosition) {
                return -1;
            }
            return 0;
        });
    }

    public get rules() {
        return {
            name: [required],
            mappedObject: [required],
            columnTemplate: {
                name: [required],
                startPosition: [required, min(1)],
                length: [required, min(1)],
                mappedField: [required],
            },
        };
    }

    public get api() {
        return this.$api.importTemplate;
    }

    public addColumnTemplate() {
        const columnDefnition: IImportColumnTemplate = {
            name: '',
            startPosition: 0,
            length: 1,
            mappedField: '',
        };

        if (this.importDef != null) {
            if (this.importDef.importColumnTemplates == null) {
                this.importDef.importColumnTemplates = [];
            }
            this.importDef.importColumnTemplates.push(columnDefnition);

            this.showExample(columnDefnition);
        }
    }

    public showExample(columnTemplate: IImportColumnTemplate) {
        this.selectedColumnTemplate = columnTemplate;
        this.exampleDialog = true;
    }

    public removeColumnTemplate(columnTemplate: IImportColumnTemplate) {
        if (this.importDef != null) {
            const columnTemplateIdx = this.importDef.importColumnTemplates.findIndex((t: any) => t === columnTemplate);
            if (columnTemplateIdx >= 0) {
                this.importDef.importColumnTemplates.splice(columnTemplateIdx, 1);
            }
        }
        this.deleteDialog = false;
    }

    public get canUpdate() {
        return this.isAdmin;
    }

    public get canCreate() {
        return this.isAdmin;
    }

    public get canSave() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    public async loadData() {
        this.loadingData = true;
        try {
            if (this.$route.name === 'importTemplateEdit') {
                const response = await this.$api.importTemplate.get(Number(this.$route.params.id));
                this.importDef = response.data!;
            } else {
                this.importDef = buildEmptyImportTemplate();
            }
        } catch (e) {
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }

        const fieldResponse = await this.$api.importTemplate.mappableFields(this.importDef!.mappedObject!.toString());
        if (fieldResponse.success) {
            this.mappableFields = fieldResponse.data!;
        }
    }

    @Watch('importDef.mappedObject')
    public async watchMappedObject(newMappedObject: string) {
        if (newMappedObject !== '') {
            const response = await this.$api.importTemplate.mappableFields(newMappedObject);
            if (response.success) {
                this.mappableFields = response.data!;
            }
        }
    }

    public created() {
        this.isEditing = this.$route.name === 'importTemplateEdit';
    }

    public beforeMount() {
        return this.loadData();
    }

    public async save() {
        const result = (this.$refs.form as any).validate();
        if (this.importDef != null && result) {
            this.loadingData = true;
            try {
                const valueToPost: IImportTemplate = this.getValueToPost();

                if (this.isEditing) {
                    const response = await this.$api.importTemplate.save(valueToPost!);
                    this.importDef = response.data;
                } else {
                    const response = await this.$api.importTemplate.create(valueToPost!);
                    this.importDef = response.data;
                }
                this.$router.push({name: 'importTemplates'});
            } catch (e) {
                this.showError('Er is een fout opgetreden bij het opslaan van de data.');
                console.error('Save error: ', e);
            } finally {
                this.loadingData = false;
            }
        }
    }

    private getValueToPost(): any {
        // Strip properties that could've been fetched from get-endpoint, but should not be post for saving.
        return this.$_.cloneDeep(this.$_.omit(this.importDef, [
            'toegevoegdOp',
            'gewijzigdOp',
            'verwijderdOp',
            'toegevoegdDoor',
            'gewijzigdDoor',
        ]));
    }
}
