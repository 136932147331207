



















































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {Pagination} from '@/utils/api-tools/pagination';
import {IRegister} from '@/models/IRegister';
import {IArbeidsmarktRegio} from '@/models/constants/IArbeidsmarktRegio';
import {IToeristenGebied} from '@/models/constants/IToeristenGebied';
import {IVeiligheidsRegio} from '@/models/constants/IVeiligheidsRegio';
import {IProvincieCode} from '@/models/constants/IProvincieCode';
import {ICoropGebied} from '@/models/constants/ICoropGebied';



@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class GemeenteEdit extends Mixins(BasicCrudMixin) {

    public registers: IRegister[] = [];
    public arbeidsmarktRegios: IArbeidsmarktRegio[] = [];
    public coropGebieden: ICoropGebied[] = [];
    public provincieCodes: IProvincieCode[] = [];
    public veiligheidsRegios: IVeiligheidsRegio[] = [];
    public toeristenGebieden: IToeristenGebied[] = [];

    public getApi(): GenericApi<Idable> {
        return this.$api.gemeente;
    }

    public get rules() {
        return {
            omschrijving: [required],
            werkgebied: [],
            id: [required],
        };
    }

    public buildEmptyModelData(): Idable {
        return {
            omschrijving: null,
            werkgebied: false,
        } as unknown as Idable;
    }

    public async beforeMount(): Promise<void> {
        const response = await this.$api.register.list(new Pagination(0, -1), []);
        const responseArbeidsmarktRegios = await this.$api.arbeidsmarktRegio.list(new Pagination(0, -1), []);
        const responseCoropGebieden = await this.$api.coropGebied.list(new Pagination(0, -1), []);
        const responseProvincieCodes = await this.$api.provincieCode.list(new Pagination(0, -1), []);
        const responseVeiligheidsRegios = await this.$api.veiligheidsRegio.list(new Pagination(0, -1), []);
        const responseToeristenGebieden = await this.$api.toeristenGebied.list(new Pagination(0, -1), []);
        this.registers = response.data!.content;
        this.arbeidsmarktRegios = responseArbeidsmarktRegios.data!.content;
        this.coropGebieden = responseCoropGebieden.data!.content;
        this.provincieCodes = responseProvincieCodes.data!.content;
        this.veiligheidsRegios = responseVeiligheidsRegios.data!.content;
        this.toeristenGebieden = responseToeristenGebieden.data!.content;
    }
}
