var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('constant-list-base-component',{attrs:{"api-path":_vm.api,"list-headers":_vm.listHeaders},scopedSlots:_vm._u([{key:"search_arbeidsmarktRegio",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.arbeidsmarkRegioOptions,"autocomplete":"off","hide-details":"auto","label":"Arbeidsmarktregio","item-text":function (item) { return item.code + ' - ' + item.omschrijving  },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_coropGebied",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.coropGebiedOptions,"autocomplete":"off","hide-details":"auto","label":"COROP gebied","item-text":function (item) { return item.code + ' - ' + item.omschrijving  },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_provincieCode",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.provincieCodeOptions,"autocomplete":"off","hide-details":"auto","label":"Provinciecode","item-text":function (item) { return item.code + ' - ' + item.omschrijving  },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_veiligheidsRegio",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.veiligheidsRegioOptions,"autocomplete":"off","hide-details":"auto","label":"Veiligheidsregio","item-text":function (item) { return item.code + ' - ' + item.omschrijving  },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_toeristenGebied",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.toeristenGebiedOptions,"autocomplete":"off","hide-details":"auto","label":"Toeristengebied","item-text":function (item) { return item.code + ' - ' + item.omschrijving },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }