





































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import ConstantListBaseComponent from '@/components/ConstantListBaseComponent.vue';
import {Idable} from '@/models/Idable';

@Component({
    components: {ConstantListBaseComponent, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class GemeenteList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public listHeaders: any[] = [
        {text: 'Code', value: 'code', searchable: true},
        {text: 'Naam', value: 'omschrijving', searchable: true},
        {text: 'Werkgebied', value: 'werkgebied', searchable: false, type: 'boolean'},
        {text: 'Register', value: 'register', type: 'objectWithName'},
        {text: 'Arbeidsmarktregio', value: 'arbeidsmarktRegio',
            searchable: true, searchField: 'arbeidsmarktRegio.id', type: 'objectWithDescription'},
        {text: 'COROP gebied', value: 'coropGebied',
            searchable: true, searchField: 'coropGebied.id', type: 'objectWithDescription'},
        {text: 'Provinciecode', value: 'provincieCode',
            searchable: true, searchField: 'provincieCode.id', type: 'objectWithDescription'},
        {text: 'Veiligheidsregio', value: 'veiligheidsRegio',
            searchable: true, searchField: 'veiligheidsRegio.id', type: 'objectWithDescription'},
        {text: 'Toeristengebied', value: 'toeristenGebied',
            searchable: true, searchField: 'toeristenGebied.id', type: 'objectWithDescription'},
        {text: 'Actief', value: 'active', searchable: false, type: 'boolean'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public arbeidsmarkRegioOptions: Idable[] = [];
    public coropGebiedOptions: Idable[] = [];
    public provincieCodeOptions: Idable[] = [];
    public veiligheidsRegioOptions: Idable[] = [];
    public toeristenGebiedOptions: Idable[] = [];

    public get api() {
        return this.$api.gemeente;
    }

    public async created() {
        this.arbeidsmarkRegioOptions = (await this.$api.arbeidsmarktRegio.listAll()).data?.content || [];
        this.coropGebiedOptions = (await this.$api.coropGebied.listAll()).data?.content || [];
        this.provincieCodeOptions = (await this.$api.provincieCode.listAll()).data?.content || [];
        this.veiligheidsRegioOptions = (await this.$api.veiligheidsRegio.listAll()).data?.content || [];
        this.toeristenGebiedOptions = (await this.$api.toeristenGebied.listAll()).data?.content || [];
    }
}
