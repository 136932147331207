




















































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import ConstantListBaseComponent from '@/components/ConstantListBaseComponent.vue';
import {IMeetperiode} from '@/models/constants/IMeetperiode';
import {date} from '@/utils/filters';
import {IRegister} from '@/models/IRegister';
import {number, required} from '@/utils/validation';
import {FREEZE_ALL_REGISTER_ID} from '@/models/IMeetperiode';

@Component({
    filters: {
        date,
    },
    components: {ConstantListBaseComponent, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class MeetperiodeList extends Mixins(ListsObjects, AuthorizationMixin) {
    public drawer: boolean = true;
    public variableName: string = 'meetperiode';
    public listHeaders: any[] = [
        {text: 'ID', value: 'id', searchable: true},
        {text: 'Naam', value: 'naam', searchable: true},
        {text: 'Peildatum', value: 'peildatumStartOfDay', searchable: false, type: 'date'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public refresher: number = 0;
    public selectedMeetperiode: IMeetperiode | null = null;
    public acceptOverride = false;
    public loading: boolean = false;
    public showProgressDialog: boolean = false;
    public registerOptions: IRegister[] = [];
    public selectedRegister: IRegister | undefined;

    public get api() {
        return this.$api.meetperiode;
    }

    public get rules() {
        return {
            register: [required],
        };
    }

    public get lastFrozenRegisterName() {
        if (this.selectedMeetperiode?.lastFrozenRegister?.id === FREEZE_ALL_REGISTER_ID) {
            return 'Alle registers';
        }
        return this.registerOptions.find((r) => r.id === this.selectedMeetperiode?.lastFrozenRegister?.id)?.naam;
    }

    public async created() {
        this.registerOptions = (await this.$api.register.listAll()).data?.content || [];
        // currently, we do not want to offer the option to freeze all registers anymore.
        // this.registerOptions.unshift({id: FREEZE_ALL_REGISTER_ID, naam: 'Alle'} as IRegister);
    }

    public closeDialog() {
        this.acceptOverride = false;
        this.selectedMeetperiode = null;
        this.showProgressDialog = false;
    }

    public async confirmFreezeData() {
        const result = (this.$refs.form as any).validate();
        if (result) {
            this.loadingData = true;
            this.loading = true;
            try {
                await this.$api.werknemerStat.freeze(this.selectedMeetperiode!, this.selectedRegister!.id as number);
                this.loading = false;
                this.loadingData = false;
                window.location.reload();
            } catch (e) {
                this.selectedMeetperiode = null;
                this.loading = false;
                this.loadingData = false;
                this.showMessage('Er is iets foutgegaan bij het starten van het bevriezen van de data.');
            }
        }
    }

    public async cancelAllFreeze() {
        this.loading = true;
        await this.$api.werknemerStat.cancelAllFreezes();
        this.loading = false;
        this.selectedMeetperiode = null;
        this.debouncedLoadData();
        this.refresher++;
    }

    public getProgress(meetperiode: IMeetperiode) {
        if (meetperiode.createWerknemerStatsInProgress) {
            if (!meetperiode.totalVestigingenToProcess) {
                return 'Process gestart';
            }
            const ratio = meetperiode.processedVestigingen / meetperiode.totalVestigingenToProcess;
            const percentage = Math.round(ratio * 10000) / 100;
            return percentage + '%';
        }
        if (meetperiode.totalWerknemerstats === 0) {
            return '100%';
        } else {
            if (!meetperiode.totalWerknemerstats) {
                return 'Process gestart';
            }
            if (!meetperiode.processedWerknemerstats) {
                return 'Bezig met opschoning';
            }
            const ratio = meetperiode.processedWerknemerstats / meetperiode.totalWerknemerstats;
            const percentage = Math.round(ratio * 10000) / 100;
            return percentage + '%';
        }
    }

    public openDialog(model: IMeetperiode) {
        this.selectedMeetperiode = model;
        this.loading = true;
        this.loading = false;
    }

    public toEdit(id: string|number) {
        if (!this.selectedMeetperiode) {
            const idParam = id.toString();
            this.$router.push({name: this.editRouteName, params: {id: idParam}});
        }
    }
}
