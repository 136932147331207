




























































    import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
    import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
    import {ListsObjects} from '@/mixins/ListsObjects';
    import {Component, Mixins} from 'vue-property-decorator';
    import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
    import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
    import ConstantListBaseComponent from '@/components/ConstantListBaseComponent.vue';
    import {sectionLetterOptions} from '@/models/constants/ISbi';
    import {Idable} from '@/models/Idable';

    @Component({
    components: {ConstantListBaseComponent, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class SbiList extends Mixins(ListsObjects, AuthorizationMixin) {
    public sectionLetterOptions: string[] = sectionLetterOptions;

    public topSectorOptions: Idable[] = [];
    public afdelingOptions: Idable[] = [];
    public groepOptions: Idable[] = [];
    public klasseOptions: Idable[] = [];

    public drawer: boolean = true;
    public variableName: string = 'sbi';
    public listHeaders: any[] = [
        {text: 'Code', value: 'code', searchable: true},
        {text: 'Naam', value: 'naam', searchable: true},
        {text: 'Omschrijving', value: 'omschrijving', searchable: true},
        {text: 'Sectie', value: 'sectie', searchable: true},
        {text: 'Top sector', value: 'sbiTopSector', searchable: true, searchField: 'sbiTopSector.omschrijving', type: 'listObjectWithDescription'},
        {text: 'Afdeling', value: 'afdeling', searchable: true, searchField: 'afdeling'},
        {text: 'Groep', value: 'groep', searchable: true, searchField: 'groep'},
        {text: 'Klasse', value: 'klasse', searchable: true, searchField: 'klasse'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public async created() {
        this.topSectorOptions = (await this.$api.sbiTopSector.listAll()).data?.content || [];
        this.afdelingOptions = (await this.$api.sbiAfdeling.listAll()).data?.content || [];
        this.groepOptions = (await this.$api.sbiGroep.listAll()).data?.content || [];
        this.klasseOptions = (await this.$api.sbiKlasse.listAll()).data?.content || [];
    }

    public get api() {
        return this.$api.sbi;
    }
}
