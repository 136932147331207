var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('constant-list-base-component',{attrs:{"api-path":_vm.api,"list-headers":_vm.listHeaders},scopedSlots:_vm._u([{key:"search_sectie",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.sectionLetterOptions,"autocomplete":"off","hide-details":"auto","label":"Sectie","item-text":function (item) { return item.code + ' - ' + item.omschrijving },"item-value":function (item) { return item.id },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_sbiTopSector",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.topSectorOptions,"autocomplete":"off","hide-details":"auto","label":"Top sector","item-text":function (item) { return item.omschrijving },"item-value":function (item) { return item.omschrijving },"clearable":""},model:{value:(searchValue['topSectoren:contains']),callback:function ($$v) {_vm.$set(searchValue, 'topSectoren:contains', $$v)},expression:"searchValue['topSectoren:contains']"}})]}},{key:"search_afdeling",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.afdelingOptions,"autocomplete":"off","hide-details":"auto","label":"Afdeling","item-text":function (item) { return item.code },"item-value":function (item) { return item.code },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_groep",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.groepOptions,"autocomplete":"off","hide-details":"auto","label":"Groep","item-text":function (item) { return item.code },"item-value":function (item) { return item.code },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}},{key:"search_klasse",fn:function(ref){
var header = ref.header;
var searchValue = ref.searchValue;
return [_c('v-autocomplete',{attrs:{"items":_vm.klasseOptions,"autocomplete":"off","hide-details":"auto","label":"Klasse","item-text":function (item) { return item.code },"item-value":function (item) { return item.code },"clearable":""},model:{value:(searchValue[header.searchField || header.value]),callback:function ($$v) {_vm.$set(searchValue, header.searchField || header.value, $$v)},expression:"searchValue[header.searchField || header.value]"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }