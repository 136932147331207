import {Idable} from '@/models/Idable';
import {buildEmptyContact, ContactType, IContact} from '@/models/IContact';
import {buildEmptyAdres, IAdres} from '@/models/IAdres';
import {ISbi} from '@/models/constants/ISbi';
import {IRechtsvorm} from '@/models/constants/IRechtsvorm';
import {IVestigingType} from '@/models/constants/IVestigingType';
import {IWijzeStart} from '@/models/constants/IWijzeStart';
import {IWijzeEinde} from '@/models/constants/IWijzeEinde';
import {IUitzetCode} from '@/models/constants/IUitzetCode';
import {ICb} from '@/models/ICb';
import {IRegister} from '@/models/IRegister';
import {IWerknemerStat} from '@/models/IWerknemerStat';
import {IOverheidsCode} from '@/models/constants/IOverheidsCode';
import {buildEmptyCorrespondent, ICorrespondent} from '@/models/ICorrespondent';
import {IGrootteklasse} from '@/models/constants/IGrootteklasse';
import {IKvk} from '@/models/IKvk';
import {IOpmerking} from '@/models/IOpmerking';
import {IBehandelingType} from '@/models/constants/IBehandelingType';

export interface IVestiging extends Idable {
    vestigingNummer: number|null;
    kvkNummerOud: number|null;
    kvkVestigingNummer: number|null;
    kvkId: number|null;
    naam: string;
    vervangendeTitel: string|null;
    datumStart: number|null;
    datumEinde: number|null;
    datumOpname: number|null;
    datumVestiging: number|null;
    datumVestigingHuidigAdres: number|null;
    rsin: string|null;
    contact: IContact|null;
    wpContact: IContact|null;
    adres: IAdres;
    correspondent: ICorrespondent;
    overheidsCode: IOverheidsCode|null;
    sbi: ISbi|null;
    sbiMutatieDatum: number|null;
    naamMutatieDatum: number|null;
    rechtsvorm: IRechtsvorm|null;
    grootteklasse: IGrootteklasse|null;
    grootteklasseBron: GrootteklasseBron|null;
    vestigingType: IVestigingType|null;
    wijzeStart: IWijzeStart|null;
    wijzeEinde: IWijzeEinde|null;
    uitzetCode: IUitzetCode|null;
    kvkDossiernummer: string;
    werknemerStats: IWerknemerStat[]|null;
    cb: ICb|null;
    registers: IRegister[]|null;
    mutatieToelichting: null|string;
    opmerkingen: IOpmerking[];
    extra: boolean|null;
    datumBehandeling: number|null;
    behandelingType: IBehandelingType|null;
    behandelingOpmerking: string | null;
    kvk: IKvk|null;

    // readonly
    huisnummer: number|null;
    postcode: string|null;
    huisletter: string|null;
    toevoeging: string|null;
}

export function buildEmptyVestiging(): IVestiging {
    const vestiging = {
        id: '',
        naam: '',
        datumStart: null,
        datumEinde: null,
        datumOpname: new Date().getTime(),
        rsin: null,
        contact: buildEmptyContact(ContactType.VESTIGING_GEBOUW),
        wpContact: buildEmptyContact(ContactType.CONTACT_PERSOON),
        adres: buildEmptyAdres(),
        correspondent: {},
        overheidsCode: {},
        sbi: {},
        sbiMutatieDatum: null,
        rechtsvorm: {},
        grootteklasse: null,
        grootteklasseBron: GrootteklasseBron.HM,
        vestigingType: {},
        wijzeStart: {},
        wijzeEinde: {},
        uitzetCode: {},
        kvkDossiernummer: '',
        kvkId: null,
        cb: {id: 0},
        registers: [],
        werknemerStats: [],
        mutatieToelichting: null,
        opmerkingen: [],
    } as unknown as IVestiging;
    vestiging.correspondent = buildEmptyCorrespondent();
    return vestiging;
}

export enum GrootteklasseBron {
    KVK = 1,
    HM = 2,
    ENQ = 3,
}

export enum MutatieCode {
    HANDMATIG = 0,
    AUTOMATISCH = 1,
    EXTERN = 2,
}

export enum MutatieSoort {
    START = 'START',
    ENV = 'ENV',
    KVKNR = 'KVKNR',
    OPV = 'OPV',
    SBI = 'SBI',
    ACT = 'ACT',
    NAAM = 'NAAM',
    ADRES = 'ADRES',
    AFV = 'AFV',
    BEHANDELING = 'BEHANDELING',
}
